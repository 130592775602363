<template>
 <a-spin :spinning="loading">
   <div style="max-width: 500px;margin: 0 auto;display: flex;flex-direction: column;justify-content: flex-start;height: 100vh;overflow-y: hidden">
     <div class="title">
       订单生产进度
     </div>
     <div class="container" style="flex: 1">
         <div class="card">
           <div class="header">
             <div>{{model.code || ''}}</div>
             <div><s-tag :v="model.status_tag_class" :text="model.status_name"></s-tag></div>
           </div>
           <div>
             <span class="label">{{model['goods.name'] || '商品'}}名</span><span>{{model.goods && model.goods.name || '无'}}</span>
           </div>
           <div><span class="label">编号/规格</span><span>{{model.goods && model.goods.code || '无'}}/{{model.goods && model.goods.standard || '无'}}</span></div>
           <div><span class="label">工序进度</span><span>{{parseFloatBySystem(model.finish_craft_count,8) || 0}}/{{parseFloatBySystem(model.all_craft_count,8) || 0}}</span></div>
           <div style="display: flex;justify-content: flex-start;padding-right: 20px">
             <a-progress :percent="myProgress(model.finish_craft_count, model.all_craft_count)"/>
           </div>
         </div>
         <div class="card">
           <div class="header">
             <div>工序进度</div>
             <div></div>
           </div>
           <div class="detail">
             <div class="craft_list_detail" v-for="(item,index) in model.craft_list" :key="index">
               <a-progress
                   type="circle"
                   :percent="100"
                   :strokeColor="item.status_color"
                   :width="50"
                   :strokeWidth="8"
               >
                 <template #format>
                   <span style="color:#000000A6">{{ parseFloat(item.valid_num) === 0 || parseFloat(item.num * item.ratio) === 0 ? 0 : (Math.floor(parseFloatBySystem(item.valid_num,8) / parseFloatBySystem(item.num * item.ratio,8) * 100)) }}%</span>
                 </template>
               </a-progress>
               <a-tooltip :title="item.pub_craft.name" placement="topLeft">
                 <span class="progress_text">
                     {{item.pub_craft.name}}
                 </span>
               </a-tooltip>
             </div>
           </div>
         </div>
     </div>
   </div>
 </a-spin>
</template>

<script>


import request from "@/utils/request";
import STag from '@/components/STag'
import {myProgress, myProgressFormat, parseFloatBySystem} from "@/utils/utils";
export default {
  name: "InsideProduceBillList",
  components: {STag},
  data() {
    return {
      model:{},
      id:parseInt(this.$route.params.id),
      loading:false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    myProgress,
    myProgressFormat,
    parseFloatBySystem,
    loadData() {
      this.loading = true
      request({
        url: '/produce_bill/progress',
        data: {id: this.id}
      }).then(res => {
        if(res.data.data.length<=0){
          this.$router.push({name: '404'})
        }else {
          this.model = res.data.data
        }
      }).catch(() => this.$router.push({name: '404'})).finally(() => {
        this.loading = false
      })
    }
  }
};
</script>
<style scoped lang="less">
.title{
  width: 100%;
  height: 50px;
  background: #00A578;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  background: #f6f6f6;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 10px 20px;
  overflow-y: auto;
  .card{
    margin-bottom: 20px;
    background: #fff;
    padding: 10px;
    color: #9A9A9A;
    border-radius: 6px;
    .label{
      margin-right: 4px;
    }
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 0;
      font-size: 16px;
      font-weight: bold;
      color: #232a25;
    }
    //.progress_container + .progress_container:before{
    //  content: '';
    //  width: 28px;
    //  height: 1px;
    //  margin: 0 8px;
    //  display: inline-block;
    //  background-color: #bbb;
    //  position: absolute;
    //  left: -37px;
    //  top: 34%;
    //}
    .detail{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .craft_list_detail{
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        /deep/ .default-percent-text{
          font-weight: 600;
          font-size: 10px;
          white-space: nowrap;
          vertical-align: middle;
          transform: scale(.83);
        }
        .progress_text{
          color: rgb(154, 154, 155);
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 50px;
          text-align: center;
        }
      }
    }
  }
}
</style>
