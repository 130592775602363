<template>
  <div :class="['tag-view', 'div-color-' + color(v)]" @click="onClick">
    {{ text }}
  </div>
</template>

<script>
import enums from '@/utils/enums'
export default {
  name: 'Index',
  props: {
    v: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      enums,
      color: (v) => {
        switch (v) {
          case enums.S_TAG.BLUE.v:
            return enums.S_TAG.BLUE.name
          case enums.S_TAG.RED.v:
            return enums.S_TAG.RED.name
          case enums.S_TAG.ORANGE.v:
            return enums.S_TAG.ORANGE.name
          case enums.S_TAG.GRAY.v:
            return enums.S_TAG.GRAY.name
          case enums.S_TAG.GREEN.v:
            return enums.S_TAG.GREEN.name
          case enums.S_TAG.CYAN.v:
            return enums.S_TAG.CYAN.name
          case enums.S_TAG.PINK.v:
            return enums.S_TAG.PINK.name
          case enums.S_TAG.YELLOW.v:
            return enums.S_TAG.YELLOW.name
          default:
            return enums.S_TAG.GRAY.name
        }
      }
    }
  },
  methods: {
    onClick () {
      this.$emit('onClick')
    }
  }
}
</script>

<style scoped>
.tag-view{
  padding: 1px 7px;
  font-size: 12px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  margin: 0;
  text-align: center;
  cursor: default;
}
.div-color-blue{
  border-color: rgba(0, 165, 120, 0.54);
  color: #00A578;
  background-color: rgba(180, 231, 216, 0.38);
}
.div-color-red{
  border-color: #ffa39e;
  color: #f5222d;
  background-color: #fff1f0;
}
.div-color-orange{
  border-color: #ffd591;
  color: #fa8c16;
  background-color: #fff7e6;
}
.div-color-gray{
  border-color: #b7b6b6;
  color: #757575;
  background-color: #fafafa;
}
.div-color-green{
  border-color: #b7eb8f;
  color: #52c41a;
  background-color: #f6ffed;
}
.div-color-cyan{
  border-color: #87e8de;
  color: #13c2c2;
  background-color: #e6fffb;
}
.div-color-pink{
  border-color: #ffadd2;
  color: #eb2f96;
  background-color: #fff0f6;
}
.div-color-yellow{
  border-color: #c2c231;
  color: #b1b116;
  background-color: #FAFAD2;;
}
</style>
